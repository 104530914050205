import axios from 'axios';

const baseURL = import.meta.env.VITE_API_BASE;

const instance = axios.create({
    baseURL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    },
});
instance.interceptors.response.use(
    (response) => {
        const { data, status } = response;
        if (status === 200) {
            return data;
        }
        return response;
    },
    function (error) {
        return Promise.reject(error.response.data);
    },
);
instance.interceptors.request.use(function (config) {
    return config;
});

export default instance;
